import basketball from '../../../img/icons/sporttype/basketball.svg'
import iceHockey from '../../../img/icons/sporttype/icehockey.svg'
import golf from '../../../img/icons/sporttype/golf.svg'
import handball from '../../../img/icons/sporttype/handball.svg'
import baseball from '../../../img/icons/sporttype/lacrosse.svg'
import horses from '../../../img/icons/sporttype/horses.svg'
import fieldHockey from '../../../img/icons/sporttype/fieldhockey.svg'
import soccer from '../../../img/icons/sporttype/soccer.svg'
import padelTennis from '../../../img/icons/sporttype/padeltennis.svg'
import tennis from '../../../img/icons/sporttype/tennis.svg'
import volleyball from '../../../img/icons/sporttype/volleyball.svg'
import waterpolo from '../../../img/icons/sporttype/waterpolo.svg'
import musical from '../../../img/icons/sporttype/musical.svg'
import church from '../../../img/icons/sporttype/church.svg'
import football from '../../../img/icons/sporttype/footballBlack.svg'
import lacrosse from '../../../img/icons/sporttype/lacrosseBlack.svg'
import i18n from 'i18next'

export type EventTypes = {
  value: string
  label: string
  img: string
  needsAgeClass: boolean
  singleClubEvent: boolean
}

export const eventTypes = [
  { value: 'soccer', label: 'Soccer', img: soccer, needsAgeClass: true, singleClubEvent: false },
  { value: 'icehockey', label: 'Icehockey', img: iceHockey, needsAgeClass: true, singleClubEvent: false },
  { value: 'hockey', label: 'Hockey', img: fieldHockey, needsAgeClass: true, singleClubEvent: false },
  { value: 'handball', label: 'Handball', img: handball, needsAgeClass: true, singleClubEvent: false },
  { value: 'baseball', label: 'Baseball', img: baseball, needsAgeClass: true, singleClubEvent: false },
  { value: 'basketball', label: 'Basketball', img: basketball, needsAgeClass: true, singleClubEvent: false },
  { value: 'volleyball', label: 'Volleyball', img: volleyball, needsAgeClass: true, singleClubEvent: false },
  { value: 'waterpolo', label: 'Waterpolo', img: waterpolo, needsAgeClass: true, singleClubEvent: false },
  {
    value: 'americanFootball',
    label: 'Football',
    img: football,
    needsAgeClass: true,
    singleClubEvent: false
  },
  { value: 'motorball', label: 'Motorball', img: soccer, needsAgeClass: true, singleClubEvent: false },
  { value: 'lacrosse', label: 'Lacrosse', img: lacrosse, needsAgeClass: true, singleClubEvent: false },
  { value: 'golf', label: 'Gold', img: golf, needsAgeClass: true, singleClubEvent: false },
  { value: 'tennis', label: 'Tennis', img: tennis, needsAgeClass: true, singleClubEvent: false },
  {
    value: 'padeltennis',
    label: 'Padeltennis',
    img: padelTennis,
    needsAgeClass: true,
    singleClubEvent: false
  },
  { value: 'horses', label: 'Jumping', img: horses, needsAgeClass: false, singleClubEvent: true },
  {
    value: 'dressage',
    label: 'Dressage - Individuall',
    img: horses,
    needsAgeClass: false,
    singleClubEvent: true
  },
  {
    value: 'dressage_group',
    label: 'Dressage - Group',
    img: horses,
    needsAgeClass: false,
    singleClubEvent: true
  },
  { value: 'musical', label: 'Musical', img: musical, needsAgeClass: false, singleClubEvent: false },
  { value: 'church', label: 'Church', img: church, needsAgeClass: false, singleClubEvent: true }
]

export const stream = [
  {
    value: 'cameraman',
    text: i18n.t('recordingPlan.advanced.streamTypes.autoStream.title'),
    oldText: 'CAMERA MAN',
    description: i18n.t('recordingPlan.advanced.streamTypes.autoStream.description')
  },
  {
    value: 'quarterBoxPanorama',
    text: i18n.t('recordingPlan.advanced.streamTypes.trainingStream.title'),
    oldText: 'VR STREAM',
    description: i18n.t('recordingPlan.advanced.streamTypes.trainingStream.description')
  },
  {
    value: 'scoutingFeedSoccer',
    text: i18n.t('recordingPlan.advanced.streamTypes.analyseStream.title'),
    oldText: 'SCOUTING',
    description: i18n.t('recordingPlan.advanced.streamTypes.analyseStream.description'),
    onlyStreamType: ['soccer']
  },
  {
    value: 'scoutingFeedIcehockey',
    text: i18n.t('recordingPlan.advanced.streamTypes.analyseStream.title'),
    oldText: 'SCOUTING',
    description: i18n.t('recordingPlan.advanced.streamTypes.analyseStream.description'),
    onlyStreamType: ['icehockey'],
    default: ['icehockey']
  },
  {
    value: 'fullField',
    text: i18n.t('recordingPlan.advanced.streamTypes.vrStream.title'),
    oldText: 'FULL FIELD',
    description: i18n.t('recordingPlan.advanced.streamTypes.vrStream.description')
  }
]

export const streamTraining = [
  {
    value: 'quarterBoxPanorama',
    text: 'Trainings-Kamera',
    oldText: 'VR STREAM',
    description:
      'VR-Stream, Trainingsbetrieb, für manuelle Kameraführung, Anwender legt den Kamerafokus fest (Standard bei Training)'
  },
  {
    value: 'scoutingFeedSoccer',
    text: 'Analyse-Kamera',
    oldText: 'SCOUTING',
    description: 'KI-Stream, weiter rausgezoomt um mehr vom Spiel zu sehen',
    onlyStreamType: ['soccer']
  },
  {
    value: 'scoutingFeedIcehockey',
    text: 'Analyse-Kamera',
    oldText: 'SCOUTING',
    description: 'KI-Stream, weiter rausgezoomt um mehr vom Spiel zu sehen',
    onlyStreamType: ['icehockey'],
    default: ['icehockey']
  },
  {
    value: 'fullField',
    text: '180°-Stream',
    oldText: 'FULL FIELD',
    description: 'Permanente Ansicht des gesamten Spielfeldes'
  }
]
